<template>
  <freelancer-profile :freelancer-id="$route.params.freelancer_id" />
</template>

<script>
import FreelancerProfile from '~/components/Freelancer/Profile'

export default {
  components: {
    FreelancerProfile,
  },
  mounted() {
    this.$store.dispatch('employerMailingUpdate', {
        freelancer_id : parseInt(this.$route.params.freelancer_id),
        clicked : true
    })
  }
}
</script>
